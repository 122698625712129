$(document).on('turbolinks:load', function() {
    let contacts_datatable = $('#contacts_datatable').DataTable({
        "processing": true,
        "serverSide": true,
        "scrollX": true,
        "ajax": {
            "url": $('#contacts_datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "company", visible: $('#current_user_is_super_admin').val() == 'true'},
            {"data": "specialty"},
            {"data": "first_name"},
            {"data": "last_name"},
            {"data": "email"},
            {"data": "phone"},
            {"data": "company_name"},
            {"data": "description"}
        ]
    });


    $('#contacts_datatable').on( 'click', 'tbody tr', function () {
        let id = contacts_datatable.row( this ).id();

        if (id != undefined) {
            window.location = '/contacts/'+id;
        }
    });
});