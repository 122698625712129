$(document).on('turbolinks:load', function() {
    $('#report_type').on('change', function() {
        if($(this).val() == "") {
            $('#report_client').attr('disabled', true);
            $('#report_client').parent().addClass('d-none');

            $('#report_include_session_care_notes').attr('disabled', true);
            $('#report_include_session_care_notes').parent().addClass('d-none');

            $('#report_date').attr('disabled', true);
            $('#report_date').parent().addClass('d-none');
        } else if ($(this).val() == "Care Plan Report") {
            $('#report_client').attr('disabled', false);
            $('#report_client').parent().removeClass('d-none');

            $('#report_include_session_care_notes').attr('disabled', false);
            $('#report_include_session_care_notes').parent().removeClass('d-none');

            $('#report_date').attr('disabled', true);
            $('#report_date').parent().addClass('d-none');
        } else {
            $('#report_client').attr('disabled', true);
            $('#report_client').parent().addClass('d-none');

            $('#report_include_session_care_notes').attr('disabled', true);
            $('#report_include_session_care_notes').parent().addClass('d-none');

            $('#report_date').attr('disabled', false);
            $('#report_date').parent().removeClass('d-none');
        }
    });

    $('#report_include_session_care_notes').on('change', function() {
        if($(this).is(':checked')) {
            $('#report_date').attr('disabled', false);
            $('#report_date').parent().removeClass('d-none');
        } else {
            $('#report_date').attr('disabled', true);
            $('#report_date').parent().addClass('d-none');
        }
    });
});