// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

require("bootstrap");
require("datatables.net-bs4");
require("moment");
require("daterangepicker");
require("select2");
require("@fortawesome/fontawesome-free/js/all.min");

require("../custom/global");
require("../custom/filters");
require("../custom/companies");
require("../custom/users");
require("../custom/locations");
require("../custom/appointments");
require("../custom/conversations");
require("../custom/domains");
require("../custom/dashboard");
require("../custom/contacts");
require("../custom/reports");

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
