import consumer from "./consumer"

consumer.subscriptions.create("NewMessageChannel",{
    connected() {
        console.log('Connected to NewMessageChannel')
    },
    disconnected() {
        console.log('Disconnected from NewMessageChannel')
    },
    received(data) {
        console.log('Received on NewMessageChannel')
        console.log(data)
        $('#new_messages_count').html(data.new_messages_count)
    }
});
