$(document).on('turbolinks:load', function() {
    let locations_datatable = $('#locations_datatable').DataTable({
        "processing": true,
        "serverSide": true,
        "scrollX": true,
        "ajax": {
            "url": $('#locations_datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "company", visible: $('#current_user_is_super_admin').val() == 'true'},
            {"data": "name"},
            {"data": "phone"},
            {"data": "fax"},
            {"data": "category"},
            {"data": "description"}
        ]
    });


    $('#locations_datatable').on( 'click', 'tbody tr', function () {
        let id = locations_datatable.row( this ).id();

        if (id != undefined) {
            window.location = '/locations/'+id;
        }
    });
});