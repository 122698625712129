$(document).on('turbolinks:load', function() {
    let users_datatable = $('#users_datatable').DataTable({
        "processing": true,
        "serverSide": true,
        "scrollX": true,
        "ajax": {
            "url": $('#users_datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "company", visible: $('#current_user_is_super_admin').val() == 'true'},
            {"data": "id"},
            {"data": "first_name"},
            {"data": "last_name"},
            {"data": "email"},
            {"data": "phone"},
            {"data": "role"}
        ]
    });

    $('#users_datatable').on( 'click', 'tbody tr', function () {
        let id = users_datatable.row( this ).id();

        if (id != undefined) {
            window.location = '/users/'+id;
        }
    });

    $('#users_form').on('click', '.remove_fields', function(event) {
        $(this).parent().find('input[type=hidden]').val(true);
        $(this).closest('fieldset').hide();
        return event.preventDefault();
    });

    $('#users_form').on('click', '.add_fields', function(event) {
        var regexp, time;
        time = new Date().getTime();
        regexp = new RegExp($(this).data('id'), 'g');
        $(this).before($(this).data('fields').replace(regexp, time));
        return event.preventDefault();
    });

    if($('#user_domain_ids').length) {
        $('#user_domain_ids').select2();
    }

    $('#users_form').on('submit', function(){
        let selected_role = $( "#user_role option:selected" ).text();
        let selected_domains_count = $('#user_domain_ids').find(':selected').length;

        if (selected_role == 'Client' && selected_domains_count > 1) {
            alert('Client user can be assigned to only one domain')
            return false
        }
    });

    $('#users_form').on('change', '.user_contacts_attributes_specialty', function(){
        let selectedSpecialty = $(this).val();
        let parentFormRow = $(this).parent('.form-group').parent('.form-row');

        let searchUrl = parentFormRow.find('.user_contacts_attributes_search').attr('data-url');

        parentFormRow.find('.user_contacts_attributes_search').select2({
            ajax: {
                method: 'post',
                url: searchUrl,
                dataType: 'json',
                data: function (params) {
                    let query = {
                        term: params.term,
                        selectedSpecialty: selectedSpecialty
                    }
                    return query;
                },
                processResults: function (data) {
                    console.log(data)
                    return {
                        results: data
                    };
                }
            }
        });
    });

    $('#users_form').on('change', '.user_contacts_attributes_search', function() {
        let parentFormRow = $(this).parent('.form-group').parent('.form-row');
        parentFormRow.find('.user_contacts_attributes_company_id').prop('disabled', true);
        parentFormRow.find('.user_contacts_attributes_specialty').prop('disabled', true);
        parentFormRow.find('.user_contacts_attributes_first_name').prop('disabled', true);
        parentFormRow.find('.user_contacts_attributes_last_name').prop('disabled', true);
        parentFormRow.find('.user_contacts_attributes_email').prop('disabled', true);
        parentFormRow.find('.user_contacts_attributes_phone').prop('disabled', true);
        parentFormRow.find('.user_contacts_attributes_company_name').prop('disabled', true);
        parentFormRow.find('.user_contacts_attributes_description').prop('disabled', true);
    });

    $('#user_avatar').change(function(){
        $('#user_avatar_file_name').text(this.value);
    });
});