$( document ).on('turbolinks:load', function() {
    setTimeout(function() {
        $('.alert').fadeOut("slow");
    }, 5000);

    $(function() {
        let start
        let end
        let set_blank = true

        if ($('#filters_date').val()){
            let filters_date = $('#filters_date').val().split(" - ");
            start = moment(filters_date[0], 'YYYY-MM-DD')
            end = moment(filters_date[1], 'YYYY-MM-DD')
            set_blank = false
        } else {
            start = moment().subtract(29, 'days');
            end = moment();
        }

        $('#filters_date, #report_date').daterangepicker({
            locale: { format: 'YYYY-MM-DD'},
            startDate: start,
            endDate: end,
            ranges: {
                'Today': [moment(), moment()],
                'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                'Last 7 Days': [moment().subtract(6, 'days'), moment()],
                'Last 30 Days': [moment().subtract(29, 'days'), moment()],
                'This Month': [moment().startOf('month'), moment().endOf('month')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
            }
        });

        if (set_blank) {
            $('#filters_date').val('');
        }
    });

    window.enableSubmit = enableSubmit
    window.disableSubmit = disableSubmit

    function enableSubmit(){
        $("#submit_btn").prop('disabled', false);
    }

    function disableSubmit(){
        $("#submit_btn").prop('disabled', true);
    }
});
