$(document).on('turbolinks:load', function() {
    let url = window.location.href;

    let appointments_datatable = $('#appointments_datatable').DataTable({
        "processing": true,
        "serverSide": true,
        "scrollX": true,
        "searching": false,
        "ajax": {
            "url": $('#appointments_datatable').data('source')
        },
        "pagingType": "full_numbers",
        "order": [[ 1, "asc" ],[ 2, "asc" ]],
        "columns": [
            {"data": "company", visible: $('#current_user_is_super_admin').val() == 'true' },
            {"data": "date"},
            {"data": "time"},
            {"data": "client"},
            {"data": "location"},
            {"data": "caregiver"},
            {"data": "duration"},
            {"data": "status"},
            {"data": "created_at", visible: url.includes("request")}
        ]
    });

    $('#appointments_datatable').on( 'click', 'tbody tr', function () {
        let id = appointments_datatable.row( this ).id();

        if (id != undefined) {
            let url = window.location.href;

            if (url.includes("request")) {
                window.location = '/requests/'+id;
            } else {
                window.location = '/appointments/'+id;
            }
        }
    });

    $('#appointment_form').on('click', '.remove_fields', function(event) {
        $(this).parent().find('input[type=hidden]').val(true);
        $(this).closest('fieldset').hide();
        return event.preventDefault();
    });

    $('#appointment_form').on('click', '.add_fields', function(event) {
        let time = new Date().getTime();
        let regexp = new RegExp($(this).data('id'), 'g');
        $(this).before($(this).data('fields').replace(regexp, time));
        return event.preventDefault();
    });

    $('#appointment_form').on('change', '#appointment_date, #appointment_time_4i, #appointment_time_5i, #appointment_duration', function(event) {
        if (!$(".custom_alert").length) {
            return
        }
        $(".custom_alert").addClass('d-none');

        let form_data = $(this).parents("#appointment_form").serializeArray()
        form_data[0].value = 'post'

        $.ajax({
            type: 'POST',
            url: "/appointments/form_validate",
            data: form_data,
            dataType: "JSON"
        }).done(function(data) {
            if(data.conflict){
                $("#time_conflict").removeClass('d-none');
            }
            if(data.is_in_the_past) {
                $("#in_the_past").removeClass('d-none');
            }
            if(!data.conflict && !data.is_in_the_past){
                $("#no_conflict").removeClass('d-none');
            }
        }).fail(function() {
            alert('Time Validation Failed')
        });
    });

    $('#submit_appointment').on('click', function(event) {
        if (!$(".custom_alert").length) {
            return
        }

        if ($("#time_conflict").is(":visible") || $("#in_the_past").is(":visible")) {
            $('#conflictModal').modal();
            return false
        }
    });

    $('#overrideConflict').on('click', function(){
        $('#appointment_form').submit();
    });

    if ($("#time_entry_start_time").length) {
        let  startTime = new Date($("#time_entry_start_time").attr('value')).getTime();

        setInterval(function() {
            let now = new Date().getTime();
            let distance = now - startTime;

            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);

            $("#time_entry_start_time").text(hours + "h " + minutes + "m " + seconds + "s ");
        }, 1000);
    }

    $(".appointment-export").on('click', function(){
        let params = $.param(appointments_datatable.ajax.params());
        let url = $(this).data('source');
        let delimiter = url.includes('?') ? '&' : '?';
        if(url.includes('pdf')){
            window.open(url + delimiter + params, '_blank');
        } else {
            window.location.href = url + delimiter + params
        }
    });

    $('#appointment_repeat').on('change', function(){
        $('#appointment_interval').prop('disabled', !$(this).is(":checked"))
        $('#appointment_interval').prop('hidden', !$(this).is(":checked"))
        $('#appointment_times').prop('disabled', !$(this).is(":checked"))
        $('#appointment_times').prop('hidden', !$(this).is(":checked"))
        $('#appointment_weekend').prop('disabled', !$(this).is(":checked"))
        $('#appointment_weekend').prop('hidden', !$(this).is(":checked"))
    });

    $('.appointment_functional_status').on('change', function(){
       $("#appointment_functional_statuses_form").submit();
    });

    $('#appointment_at_client_home').on('change', function(){
       $("#appointment_location_id").attr('disabled', $(this).is(":checked"))
    });

    $('#toggle_timer').on('click', function(){
       if($(this).hasClass('btn-danger')){
           $('#sessionTimerModal').modal();
           return false
       }
    });

    $('#completeVisit').on('click', function(){
        let url = $('#toggle_timer').attr('href');
        url = url.replace("toggle_timer", "complete_appointment");
        window.location.href = url;
    });

    $('#doNotCompleteVisit').on('click', function(){
        let url = $('#toggle_timer').attr('href');
        window.location.href = url;
    });
});