$(document).on('turbolinks:load', function() {
    let companies_datatable = $('#companies_datatable').DataTable({
        "processing": true,
        "serverSide": true,
        "scrollX": true,
        "ajax": {
            "url": $('#companies_datatable').data('source')
        },
        "pagingType": "full_numbers",
        "columns": [
            {"data": "id"},
            {"data": "name"}
        ]
    });


    $('#companies_datatable').on( 'click', 'tbody tr', function () {
        let id = companies_datatable.row( this ).id();

        if (id != undefined) {
            window.location = '/companies/'+id;
        }
    });
});