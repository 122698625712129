$(document).on('turbolinks:load', function() {
    $('#show_hide_filters').on('click', function(){
        if ($("#filters").hasClass('d-none')) {
            $("#filters").removeClass('d-none');
        } else {
            $("#filters").addClass('d-none');
        }
    });

    if($('#filters_status').length){
        $('#filters_status').select2();
    }
});